import request from "./request";

// auth
export const sendEmail = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("sendMail", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
