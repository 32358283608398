import { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';

const customStyles = {
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        
    },
    content: {
        backgroundColor: '#fff',
        padding: '2rem',
        borderRadius: '0.5rem',
        textAlign: 'center',
        maxWidth: '80%',
        maxHeight: '80%',
        overflow: 'auto',
        position: 'relative'
    },
    closeButton: {
        position: 'absolute',
        top: '1rem',
        right: '1rem',
        cursor: 'pointer',
        color: '#000',
        width: '24px',
        height: '24px',
    },

};

const ThankYouModal = ({ open ,setModalOpen}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleCloseModal = () => {
        setIsOpen(false);
        setModalOpen(false)
    };

    useEffect(() => {
        if(open)
        setIsOpen(open)
    }, [open])
    
    return (
        <Modal
            open={isOpen}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            style={customStyles.modal}
        >
            <div className="bg-white p-8 rounded-lg text-center" style={customStyles.content}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x"
                    style={customStyles.closeButton}
                    onClick={handleCloseModal}
                >
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>

                 <img src={require('../assets/images/thankyou.png')} alt="Thank You" className="w-16 h-16 mx-auto mb-4" />

                <h1 className="text-xl font-bold mb-4 text-midgreen">Thank You</h1>
                <p className="text-lg mb-4 text-midgreen">
                    We appreciate your interest in our acoustic and wall panels. We will be in touch with you shortly to discuss your requirements further.
                </p>
            </div>
        </Modal>
    );
};

export default ThankYouModal;
