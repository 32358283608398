import axios from 'axios';
import { BASE_URL } from './index';
const request = axios.create({
    baseURL: BASE_URL,
    timeout: 1000000,
});
let requests = [];

request.interceptors.request.use(
    (config ) => {
        requests.push(config);
        return config;
    },
    (error ) => {
         return Promise.reject(error);
    },
);
request.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        return Promise.reject(error);
    },
);
export default request;